import React from 'react';
import { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Heading3 } from '@latitude/heading';
import { ListItem } from '@latitude/list';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import SvgInline from '@latitude/svg-inline';
import Icon from '@latitude/svg-inline/images/external-link.svg';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  LINE_HEIGHT,
  MARGIN
} from '@latitude/core/utils/constants';
import {
  FlexContainer,
  Tile,
  TileContainer,
  TileIcon,
  StyledList,
  StyledSection
} from '../_ContactUsStyle';
import PageData from '../contact-us.json';

export default () => (
  <StyledSection id="other-enquiries">
    <Heading3
      color={COLOR.BLACK}
      align={ALIGN.CENTER}
      css={`
          margin: 40px 0 24px;
          font-size: 24px;
          @media (min-width: ${BREAKPOINT.LG}) {
            font-size: 32px;
            margin: 48px 0 72px;
          }
        `}
    >
      Other enquiries
    </Heading3>

    <FlexContainer wrap leftAlign>
      {PageData.otherEnquiries.map(other => <OtherTile other={other} />)}
    </FlexContainer>
  </StyledSection>
  );

const OtherTile = ({ other }) => (
  <TileContainer half>
    <Tile tileBackground={other.tileBackground}>
      <TileIcon
        css={
            other.icon === 'chat'
              ? css`
                  path,
                  line,
                  circle,
                  g {
                    stroke: #000;
                    stroke-width: 3px;
                  }
                `
              : ''
          }
      >
        <SvgInline name={`${other.icon}`} className="h-100" />
      </TileIcon>
      <Heading3
        color={COLOR.BLACK}
        align={ALIGN.CENTER}
        marginBottom={MARGIN.M8}
      >
        {other.title}
      </Heading3>
    </Tile>
    <Tile>
      {other.callNZ && (
      <StyledList pointless>
        <ListItem>
          <Text fontWeight={FONT_WEIGHT.MEDIUM}>
            Within New Zealand call
          </Text>{' '}
          <Link
            className="no-wrap"
            target="_blank"
            href={`tel:${other.callNZ}`}
          >
            {other.callNZ}
          </Link>
        </ListItem>

        <br />
      </StyledList>
        )}

      {other.callOutsideNZ && (
      <StyledList pointless>
        <ListItem>
          <Text fontWeight={FONT_WEIGHT.MEDIUM}>
            Outside New Zealand call
          </Text>{' '}
          <Link
            className="no-wrap"
            target="_blank"
            href={`tel:${other.callOutsideNZ}`}
          >
            {other.callOutsideNZ}
          </Link>
        </ListItem>
        <br />
      </StyledList>
        )}

      <StyledList pointless>
        {other.phoneNumber && (
        <ListItem>
          <Text fontWeight={FONT_WEIGHT.MEDIUM}>Call</Text>{' '}
          <Link
            className="no-wrap"
            target="_blank"
            href={`tel:${other.phoneNumber}`}
          >
            {other.phoneNumber}
          </Link>
        </ListItem>
          )}
        {other.fax && (
        <ListItem>
          <Text fontWeight={FONT_WEIGHT.MEDIUM}>Fax</Text>{' '}
          <Link
            className="no-wrap"
            target="_blank"
            href={`tel:${other.fax}`}
          >
            {other.fax}
          </Link>
        </ListItem>
          )}
        {other.billerCode && (
        <ListItem>
          <Text fontWeight={FONT_WEIGHT.MEDIUM}>
            {other.title} <br />
            BPAY&reg; biller code
          </Text>
          <Text>{other.billerCode}</Text>
        </ListItem>
          )}
        {other.email && (
        <ListItem>
          <Text fontSize={FONT_SIZE.SMALL} lineHeight={LINE_HEIGHT.TIGHT}>
            Email:{' '}
            <Link
              className="no-wrap"
              target="_blank"
              href={`mailto:${other.email}`}
            >
              {other.email}
            </Link>
          </Text>
        </ListItem>
          )}

        {other.hours && (
        <ListItem>
          <Text fontSize={FONT_SIZE.SMALL} lineHeight={LINE_HEIGHT.TIGHT}>
            {ReactHtmlParser(other.hours)}
          </Text>
        </ListItem>
          )}
        {other.otherText && (
        <ListItem>
          <Text fontWeight={FONT_WEIGHT.MEDIUM}>
            {ReactHtmlParser(other.otherText)}
          </Text>
        </ListItem>
          )}
        {other.linkHref && other.linkLabel && (
        <Link
          href={other.linkHref}
          button="tertiary"
          target="_blank"
          css="display:inline-block; font-weight:400; font-size:14px;"
          disableDefaultEventTracking
        >
          {other.linkLabel} &nbsp;
          <Icon />
        </Link>
          )}
      </StyledList>
    </Tile>
  </TileContainer>
  );
