import React from 'react';
import styled from 'styled-components';
import { Heading4 } from '@latitude/heading';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import { COLOR, BREAKPOINT } from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { TileContainer, Tile, FlexContainer } from '../_ContactUsStyle';

export default () => (
  <Box.Section id="online-help" backgroundColor={COLOR.GREY6}>
    <TileContainer
      css={`
        position: relative;
        z-index: 10;
        margin-right: 0 !important;
        max-width: 100%;
        margin-bottom: -72px !important;
      `}
    >
      <Tile
        tileBackground="blue"
        css="display:flex; justify-content: center; align-items:center;"
      >
        <img
          src={require('../images/icon-online-help.png')}
          alt="online help"
          css="width: 200px; height: 150px;"
        />
      </Tile>

      <StyledFlexContainer>
        <TextContainer>
          <Heading4 color={COLOR.BLACK}>Connect with us online</Heading4>
          <Text>
            Our online help is full of useful information along with quick and
            easy forms for you to get in touch with our dedicated specialists
            without spending time on the phone.
          </Text>
        </TextContainer>
        <ButtonContainer>
          <Link
            trackId="visit-online-help"
            trackEventData={{
              location: "Connect with us online",
              action: 'internal-link'
            }}
            href="https://gemfinance.zendesk.com/hc/en-nz"
            button="secondary"
          >
            Visit online help
          </Link>
        </ButtonContainer>
      </StyledFlexContainer>
    </TileContainer>
  </Box.Section>
);

const StyledFlexContainer = styled(FlexContainer)`
  max-width: 100%;
  padding: 24px;
  h4,
  p {
    text-align: center;
  }
  a {
    display: inline-block;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    width: 66%;
    margin-bottom: 0 !important;
    padding: 0;
    h4,
    p {
      text-align: left;
    }
  }
`;

const TextContainer = styled.div`
  margin: 24px 0;
  @media (min-width: ${BREAKPOINT.LG}) {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ButtonContainer = styled.div`
  margin: 24px 0;
  text-align: center;
  @media (min-width: ${BREAKPOINT.LG}) {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
