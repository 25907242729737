import React from 'react';
import { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Heading3 } from '@latitude/heading';
import { ListItem } from '@latitude/list';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import SvgInline from '@latitude/svg-inline';
import Icon from '@latitude/svg-inline/images/external-link.svg';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  LINE_HEIGHT,
  MARGIN
} from '@latitude/core/utils/constants';
import {
  FlexContainer,
  Tile,
  TileContainer,
  TileIcon,
  StyledList,
  StyledSection
} from '../_ContactUsStyle';
import PageData from '../contact-us.json';

export default () => (
  <StyledSection id="product-enquiries">
    <Heading3
      color={COLOR.BLACK}
      align={ALIGN.CENTER}
      css={`
          margin: 40px 0 24px;
          font-size: 24px;
          @media (min-width: ${BREAKPOINT.LG}) {
            font-size: 32px;
            margin: 48px 0 72px;
          }
        `}
    >
      Product enquiries
    </Heading3>

    <FlexContainer wrap leftAlign>
      {PageData.productEnquiries.map(product => <ProductTile product={product} />)}
    </FlexContainer>
  </StyledSection>
  );

const ProductTile = ({ product }) => (
  <TileContainer half>
    <Tile tileBackground={product.tileBackground}>
      <TileIcon
        css={
            product.icon === 'genoapay'
              ? css`
                  width: 229px;
                  height: 37px;
                  margin: 32px auto 24px;

                  @media (min-width: ${BREAKPOINT.LG}) {
                    margin: 48px auto 24px;
                  }

                  @media (min-width: ${BREAKPOINT.LG}) and (max-width: ${BREAKPOINT.XL}) {
                    transform: scale(0.82) translateX(-20px);
                  }
                `
              : ''
          }
      >
        <SvgInline name={`${product.icon}`} />
      </TileIcon>
      <Heading3
        color={COLOR.BLACK}
        align={ALIGN.CENTER}
        marginBottom={MARGIN.M8}
      >
        {product.title}
      </Heading3>
      {product.description && (
      <Text fontSize={FONT_SIZE.SMALL} lineHeight={LINE_HEIGHT.TIGHT} align="center">
        {ReactHtmlParser(product.description)}
      </Text>
          )}
    </Tile>
    <Tile>
      {product.callNZ && (
      <StyledList pointless>
        <ListItem>
          <Text fontWeight={FONT_WEIGHT.MEDIUM}>
            Within New Zealand call
          </Text>{' '}
          <Link
            className="no-wrap"
            target="_blank"
            href={`tel:${product.callNZ}`}
          >
            {product.callNZ}
          </Link>
        </ListItem>

        <br />
      </StyledList>
        )}

      {product.callOutsideNZ && (
        <StyledList pointless>
          <ListItem>
            <Text fontWeight={FONT_WEIGHT.MEDIUM}>
              Outside New Zealand call
            </Text>{' '}
            <Link
              className="no-wrap"
              target="_blank"
              href={`tel:${product.callOutsideNZ}`}
            >
              {product.callOutsideNZ}
            </Link>
          </ListItem>
          <br />
        </StyledList>
        )}

      <StyledList pointless>
        {product.phoneNumber && (
          <ListItem>
            <Text fontWeight={FONT_WEIGHT.MEDIUM}>Call</Text>{' '}
            <Link
              className="no-wrap"
              target="_blank"
              href={`tel:${product.phoneNumber}`}
            >
              {product.phoneNumber}
            </Link>
          </ListItem>
          )}
        {product.fax && (
          <ListItem>
            <Text fontWeight={FONT_WEIGHT.MEDIUM}>Fax</Text>{' '}
            <Link
              className="no-wrap"
              target="_blank"
              href={`tel:${product.fax}`}
            >
              {product.fax}
            </Link>
          </ListItem>
          )}
        {product.billerCode && (
          <ListItem>
            <Text fontWeight={FONT_WEIGHT.MEDIUM}>
              {product.title} <br />
              BPAY&reg; biller code
            </Text>
            <Text>{product.billerCode}</Text>
          </ListItem>
          )}
        {product.hours && (
          <ListItem>
            <Text fontSize={FONT_SIZE.SMALL} lineHeight={LINE_HEIGHT.TIGHT}>
              {ReactHtmlParser(product.hours)}
            </Text>
          </ListItem>
          )}

        {product.otherText && (
          <ListItem>
            <Text fontWeight={FONT_WEIGHT.MEDIUM}>
              {ReactHtmlParser(product.otherText)}
            </Text>
          </ListItem>
          )}
        {product.liveChat &&
            product.liveChat.map(chat => (
              <ListItem>
                <Link className="no-wrap" target="_blank" href={chat.url}>
                  {chat.title}
                </Link>
              </ListItem>
            ))}
        {product.linkHref && product.linkLabel && (
          <Link
            href={product.linkHref}
            button="tertiary"
            target="_blank"
            css="display:inline-block; font-weight:400; font-size:14px;"
            disableDefaultEventTracking
          >
            {product.linkLabel} &nbsp;
            <Icon />
          </Link>
          )}
      </StyledList>
    </Tile>
  </TileContainer>
  );
