// @flow

import React from 'react';
import styled from 'styled-components';
import { BREAKPOINT, CARD_COLOR } from '@latitude/core/utils/constants';

type Props = {
  /** L frame for home page. */
  home?: boolean,
  /** L frame for loans page. */
  loans?: boolean,
  /** L frame for credit cards page. */
  cards?: boolean,
  /** L frame for infinity rewards page. */
  infinity?: boolean,
  /** L frame for personal loans page. */
  personalLoans?: boolean,
  /** L frame for travel loans page. */
  travelloans?: boolean,
  /** L frame for low rate. */
  cardLowRate?: boolean,
  /** L frame for pay it faster page. */
  payItFaster?: boolean,
  /** L frame for Contact Us page. */
  contactUs?: boolean,
  /** L frame for Personal Loan Apply page. */
  personalLoanApply?: boolean,
  /** L frame for Calculators page. */
  calculators?: boolean,
  /** L frame for Business IF page. */
  businessIF?: boolean,
  /** L frame for Personal Loan Broker page. */
  brokers?: boolean,
  /** L frame for Hardship page */
  financialHardship?: boolean,
  /** Lframe for Wedding loan page */
  weddingLoans?: boolean
};

const Lframe = (props: Props) => {
  const calculatorPageStyles = `
        background-position: 25px 0;
        z-index: auto;

        @media (min-width: ${BREAKPOINT.MD}) {
          background-position: 54vw 0;
        }

        @media (min-width: ${BREAKPOINT.LG}) {
          background-size: 1250px auto;
          background-position: 50vw 0;
        }

        @media (min-width: ${BREAKPOINT.XL}) {
          background-position: 53vw 0;
        }`;

  const L = styled.div`
        position: absolute;
        top: 48px;
        right: 0;
        z-index: 1;
        width: 100vw;
        height: 1237px;
        background-repeat: no-repeat;
        pointer-events: none;
        background-size: 720px;
        background-position: top left 15px;

        @media (min-width: ${BREAKPOINT.SM}) {
          background-position: calc(100vw - ${BREAKPOINT.SM} - ((100vw - ${
    BREAKPOINT.SM
  } ) / 2) + 15px ) 0;
        }

        @media (min-width: ${BREAKPOINT.MD}) {
          background-size: 992px;
          background-position: 50vw 0;
        }

        @media (min-width: ${BREAKPOINT.LG}) {
          background-size: 1200px;
          top: 0;

        }

      /* Home */
      ${(props: Props) =>
        props.home &&
        `background-image: url(${require('./images/lframe-home-gem-nz.png')});
        @media (max-width: ${BREAKPOINT.MD}) {
          top: 50px;
        }@media (min-width: ${BREAKPOINT.MD}) {
          top: -48px;
          background-position: 50vw 24px;
        }
        `}

      /* Loans */
      ${(props: Props) =>
        props.loans &&
        `background-image: url(${require('./images/lframe-loans.png')});`}

      /* Loans */
        ${(props: Props) =>
          props.payItFaster &&
          `background-image: url(${require('./images/lframe-pay-it-faster.png')});`}

      /* Personal Loan */
      ${(props: Props) =>
        props.personalLoans &&
        `background-image: url(${require('./images/lshape-blue.svg')});`}

      /* Wedding Loan */
      ${(props: Props) =>
        props.weddingLoans &&
        `background-image: url(${require('./images/lframe-wedding-loan.png')});
        `}

      /* Travel */
      ${(props: Props) =>
        props.travelloans &&
        `background-image: url(${require('./images/lframe-travel-gem-nz.png')});`}

        /* Debt Consolidation */
      ${(props: Props) =>
        props.debtConLoans &&
        `background-image: url(${require('./images/lframe-debtcon-v3.png')});`}

      /* Cards */
      ${(props: Props) =>
        props.cards &&
        `background-image: url(${require('./images/lframe-cards.png')});`}

      /* Infinity */
      ${(props: Props) =>
        props.infinity &&
        `background-image: url(${require('./images/lshape-purple.svg')});`}

      /* Card low rate */
      ${(props: Props) =>
        props.cardLowRate &&
        `background-image: url(${require('./images/lshape-light-blue.svg')});`}

      /* Car loans */
      ${(props: Props) =>
        props.carLoans &&
        `background-image: url(${require('./images/lframe-new-and-used-car-loans.png')});`}

      /* PL calculator */
      ${(props: Props) =>
        props.personalLoanRepaymentCalculator &&
        `
          background-image: url(${require('./images/lframe-calculator-personal-loan-repayment.png')});
          ${calculatorPageStyles}
        `}

      /* CL calculator */
      ${(props: Props) =>
        props.carLoanRepaymentCalculator &&
        `
          background-image: url(${require('./images/lframe-calculator-car-loan-repayment.png')});
          ${calculatorPageStyles}
        `}

      /* Home renovation loans */
      ${(props: Props) =>
        props.homeRenovationLoans &&
        `background-image: url(${require('./images/lframe-home-reno-loan.png')});
        @media (max-width: ${BREAKPOINT.SM}) {
          max-height: 280px;
        }
        margin-left: 40px;
        `}

      /* Contact us */
      ${(props: Props) =>
        props.contactUs &&
        `background-image: url(${require('./images/lframe-contact-us.png')});
        @media (max-width: ${BREAKPOINT.LG}) {
          top: 0px;
        }
        `}

      /* Financial hardship */
       ${(props: Props) =>
         props.financialHardship &&
         `
          background-image: url(${require('./images/lframe-financial-hardship.png')});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 30px;
          }
        `}

      /* Personal Loan Apply */
      ${(props: Props) =>
        props.personalLoanApply &&
        `
          background-image: url(${require('./images/lframe-apply.png')});
          position: relative;
          background-size: 491px;
          height: 130px;
          width: 100%;
          background-color: ${CARD_COLOR.BLUE_LIGHTER};

          @media (min-width: ${BREAKPOINT.XS}) {
            background-size: 550px;
            height: 145px;
          }

          @media (min-width: ${BREAKPOINT.SM}) {
            background-position: top right;
          }

          @media (min-width: ${BREAKPOINT.MD}) {
            background-size: 750px;
            height: 195px;
          }

          @media (min-width: ${BREAKPOINT.LG}) {
            background-size: 900px;
            height: 235px;
          }

          @media (min-width: ${BREAKPOINT.XL}) {
            background-size: 1150px;
            height: 240px;
          }
        `}
        /* Business IF */
      ${(props: Props) =>
        props.businessIF &&
        `
      background-image: url(${require('./images/lframe-business-if.png')});
        top:0;
        `}

      /* Credit Card Interest Free*/
      ${(props: Props) =>
        props.InterestFree &&
        `background-image: url(${require('./images/lshape-blue.svg')});`}

      /* Broker page */
      ${(props: Props) =>
        props.brokers &&
        `
          z-index: 0;
          background-image: url(${require('./images/lframe-brokers.png')});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 0;
          }
        `}

      ${(props: Props) =>
        props.calculators &&
        `
          background-image: url(${require('./images/lframe-calculator.png')});
        `}
        `;
  return <L {...props} />;
};

export default Lframe;
