import styled, { css } from 'styled-components';
import { List } from '@latitude/list';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';

export const Tile = styled.div`
  font-size: 14px;
  line-height: 1.43;
  font-weight: 300;
  margin: 0 24px;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding: 24px 0;

  ul {
    margin: 0;
    list-style-type: none;
  }

  li {
    margin: 0 0 10px;
    &:last-child {
      margin: 0;
    }
  }

  p:last-child {
    margin: 0;
  }

  h3 {
    font-size: 20px;
    line-height: 26px;
  }

  &:first-child {
    background-color: #fff;
    text-align: center;
    border-bottom: 0;
    margin: 0;
    padding: 24px 20px;

    ${props =>
      props.tileBackground === 'blue' &&
      css`
        background-color: #e8f4ff;
      `}

    ${props =>
      props.tileBackground === 'purple' &&
      css`
        background-color: #f2f2fe;
      `}

    ${props =>
      props.tileBackground === 'pink' &&
      css`
        background-color: #ffeff7;
      `}

    ${props =>
      props.tileBackground === 'grey' &&
      css`
        background-color: #f2f2f2;
      `}

    ${props =>
      props.tileBackground === 'green' &&
      css`
        background-color: #e7fbfd;
      `}
  }
  &:last-child {
    border-bottom: 0;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    border-bottom: 0;
    padding: 32px 24px;
    flex: 0 1 280px;
    margin-left: 0;
    margin-right: 0;
    min-height: 248px;

    &:after {
      display: block;
      width: 1px;
      height: 80%;
      content: '';
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      background-color: #e5e5e5;
    }

    &:first-child, &:last-child {
      &:after {
        display: none;
      }
    }
  }

  li {
    font-size: 16px;
    line-height: 1.5;
    small {
      font-size: 14px;
    }
  }
`;

export const StyledList = styled(List)`
  ${props =>
    props.pointless &&
    css`
      li {
        margin: 0 0 10px;
        &:before {
          display: none;
        }
      }
    `}
`;

export const TileContainer = styled.div`
  display: flex;
  background: ${COLOR.WHITE};
  flex-direction: column;
  max-width: 300px;
  margin: 0 0 30px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.07);
  z-index: 5;
  flex: 0 1 100%;

  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
    max-width: none;
    margin-left: 0;
    margin-right: 30px;
    margin-bottom: 30px;

    ${props =>
      props.half &&
      css`
        display: inline-flex;
        flex: 0 1 calc(50% - 15px);
        max-width: 535px;

        ${Tile} {
          &:first-child {
            flex: 0 1 301px;
            overflow: hidden;
          }
        }

        &:nth-child(even) {
          margin-right: 0;
        }
      `}

    ${props =>
      props.quarter &&
      css`
        display: inline-flex;
        flex: 0 1 23%;
        max-width: 277px;
      `};
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    ${props =>
      props.half &&
      css`
        ${Tile} {
          &:first-child {
            flex: 0 1 302px;
          }
        }
      `}

    ${props =>
      props.quarter &&
      css`
        display: inline-flex;
        flex: 0 1 calc(25% - 30px);
        max-width: 277px;
        &:only-child {
          min-width: 277px;
        }
      `};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    justify-content: space-between;
    ${props =>
      props.wrap &&
      css`
        flex-wrap: wrap;
      `};

    ${props =>
      props.leftAlign &&
      css`
        justify-content: flex-start;
      `};
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    margin-bottom: 35px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const TileIcon = styled.div`
  display: flex;
  margin: 0 auto 16px;
  width: 96px;
  height: 96px;
  align-items: flex-end;
  justify-content: center;
`;

export const StyledSection = styled.section`
  padding: 0;
`;
